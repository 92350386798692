import { api } from './axios'

export default {
  getQueue: async ({ instanceLocalId }) => {
    const response = await api
      .get(`/queue/${instanceLocalId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  saveCashback: async (params) => {
    const response = await api
      .post(`/save/cashback`, {
        ...params,
        instance_id: params.id,
        due_date: params.dateObj
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getCashback: async (params) => {
    const response = await api
      .get(`/list/cashback`, {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  useCashback: async (params) => {
    const response = await api
      .get(`/use/cashback`, {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  delCashback: async (params) => {
    const response = await api
      .get(`/delete/cashback`, {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getInstanceId: async ({ zapiId }) => {
    const response = await api
      .get(`/id/${zapiId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getAutomationCampaign: async (instanceId) => {
    const response = await api
      .get(`/instance/${instanceId}/campaigns`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getUserAutomationCampaign: async () => {
    const response = await api
      .get(`/user/automation-campaigns`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  saveAutomationCampaign: async ({
    instanceId,
    name,
    type,
    active,
    daysInactive
  }) => {
    const response = await api
      .post(`/instance/${instanceId}/save-automation-campaign`, {
        name,
        type,
        active,
        daysInactive
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  updateAutomationCampaign: async ({
    campaignId,
    instanceId,
    name,
    type,
    active,
    daysInactive
  }) => {
    const response = await api
      .put(`/instance/${instanceId}/save-automation-campaign/${campaignId}`, {
        name,
        type,
        active,
        daysInactive
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  deleteQueue: async ({ instanceLocalId }) => {
    const response = await api
      .delete(`/queue/${instanceLocalId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  logoutInstance: async ({ instanceLocalId }) => {
    const response = await api
      .post(`/logout/${instanceLocalId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getInstancesMaisModas: async () => {
    const response = await api
      .get('/mais-modas/instances')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  refreshInstance: async (instanceId) => {
    const response = await api
      .post(`instance/${instanceId}/refresh`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getClientsCashback: async () => {
    const response = await api
      .get('/clients-cashback')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  }
}
