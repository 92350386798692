import React, { useState, useEffect, useCallback, useRef } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import RefreshIcon from '../../assets/images/refresh-icon.png'
import QrCodeBackground from '../../assets/images/qr-code-background.png'
import { Loading } from '../../components/Loading'

import styles from './styles.module.scss'
// import images

import 'chartist/dist/scss/chartist.scss'
import Services from 'services/user'
import instanceService from 'services/instance'
import { RefreshCw, Trash } from 'react-feather'
import { ModalConfirm } from 'components/modalConfirm'
import { Button } from 'react-bootstrap'
import { useInstance } from 'hooks/useInstance'

export function InstanceDetailsV2({ match }) {
  const instanceId = match?.params?.instanceId
  const { getQueue, deleteQueue } = instanceService
  const { confirmRefreshInstance } = useInstance()

  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [isLoadingQrcode, setIsLoadingQrcode] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [instanceData, setInstanceData] = useState({ id: instanceId })
  // eslint-disable-next-line no-unused-vars
  const [qrcodeUrl, setQrcodeUrl] = useState(null)
  const [rendered, setRendered] = useState(false)
  const [showRefreshButton, setShowRefreshButton] = useState(false)
  const countQrCode = useRef(0)

  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const [queueCount, setQueueCount] = useState(0)

  const controlInterval = useRef(null)
  const controlIntervalStatus = useRef(null)
  const handleClose = () => {
    setLoading(false)
  }

  const loadInstance = useCallback(async () => {
    console.log({ countQrCode, isAuthenticated })
    if (countQrCode.current >= 1 || isAuthenticated) {
      clearInterval(controlInterval.current)
      clearInterval(controlIntervalStatus.current)
      countQrCode.current = 0
      setIsLoadingQrcode(false)
      setShowRefreshButton(true)
      return
    }
    console.log('sldk')
    countQrCode.current = countQrCode.current + 1
    // return;
    const data = JSON.parse(localStorage.getItem('@zapmais-qrcode'))
    console.log({ data })
    console.log(new Date(data?.expiration))
    if (data && data?.instanceId && data?.expiration > new Date().getTime()) {
      // O objeto ainda está válido
      const qrTemp = data.qr
      setQrcodeUrl(qrTemp)
      setShowRefreshButton(false)
      setIsLoadingQrcode(false)
      setIsAuthenticated(false)
      return
      // Faça algo com os dados
    } else {
      // O objeto expirou ou não existe
      localStorage.removeItem('@zapmais-qrcode')
    }
    const response = await Services.getQrCodeZapmais({ instanceId })
    console.log(response)
    if (response.status === 200) {
      if (response.data.connected) {
        setIsLoadingQrcode(false)
        setIsAuthenticated(true)
        clearInterval(controlInterval.current)
        countQrCode.current = 0
        setInstanceData({
          ...instanceData,
          instanceName: response?.data?.user?.name || '',
          instancePhone: response?.data?.user?.phone || '',
          connected: 'Sim'
        })
        localStorage.removeItem('@zapmais-qrcode')
      } else {
        const qr = response?.data?.qr

        const expiration = new Date().getTime() + 20000 // 60 segundos depois da hora atual

        const data = {
          instanceId,
          qr,
          expiration
        }

        localStorage.setItem('@zapmais-qrcode', JSON.stringify(data))
        setQrcodeUrl(response?.data?.qr)
        setShowRefreshButton(false)
        setIsLoadingQrcode(false)
        setIsAuthenticated(false)
      }
      console.log(response?.data)
      setLoading(false)
      return true
    } else {
      toast.error(
        response?.msg || 'Não foi possível carregar os dados da instância'
      )
      setLoading(false)
      return false
    }
  }, [instanceId, countQrCode, isAuthenticated])

  async function getMessageQueue() {
    try {
      const response = await getQueue({ instanceLocalId: instanceId })
      console.log({ response })
      if (response.status === 200 && response?.data?.count) {
        setQueueCount(response?.data?.count)
      } else {
        setQueueCount(0)
      }
    } catch (error) {
      console.log({ error })
    }
  }

  const checkInstanceStatus = useCallback(
    async (load = false) => {
      const response = await Services.getStatusInstance({ instanceId })
      console.log(response)
      if (response.status === 200) {
        if (response.data.connected) {
          setIsLoadingQrcode(false)
          setIsAuthenticated(true)
          clearInterval(controlInterval.current)
          clearInterval(controlIntervalStatus.current)
          countQrCode.current = 0
          setInstanceData({
            ...instanceData,
            instanceName: response?.data?.user?.name || '',
            instancePhone: response?.data?.user?.phone || '',
            connected: 'Sim'
          })
          getMessageQueue()
          localStorage.removeItem('@zapmais-qrcode')
        } else {
          if (load) {
            loadInstance()
          }
        }
        console.log(response?.data)
        setLoading(false)
        return true
      } else {
        toast.error(
          response?.msg || 'Não foi possível carregar os dados da instância'
        )
        setLoading(false)
        return false
      }
    },
    [instanceId, countQrCode, isAuthenticated]
  )

  function handleClickRefresh() {
    setIsLoadingQrcode(true)
    setShowRefreshButton(false)
    checkInstanceStatus(true)
    controlInterval.current = setInterval(() => {
      console.log('aqui')
      loadInstance()
    }, 60000)
    controlIntervalStatus.current = controlInterval.current = setInterval(
      () => {
        console.log('aqui')
        checkInstanceStatus()
      },
      10000
    )
  }

  async function handleRemoveQueue() {
    try {
      setLoading(true)
      const response = await deleteQueue({ instanceLocalId: instanceId })
      setLoading(false)
      setShowModalConfirm(false)
      console.log({ response })
      if (response.status === 200) {
        setQueueCount(0)
        toast.success(response?.data?.msg || 'Mensagens removidas')
      } else {
        toast.error(
          response?.response?.data?.msg ||
            'Não foi possível remover as mensagens da fila'
        )
      }
    } catch (error) {
      setShowModalConfirm(false)
      setLoading(false)
      console.log({ error })
    }
  }

  function handleCancelRemoveQueue() {
    setShowModalConfirm(false)
  }

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    }
    if (instanceId && rendered) {
      checkInstanceStatus(true)
      controlInterval.current = setInterval(() => {
        console.log('aqui')
        loadInstance()
      }, 60000)
      controlIntervalStatus.current = setInterval(() => {
        console.log('aqui')
        checkInstanceStatus()
      }, 10000)
    }

    return () => {
      clearInterval(controlInterval.current)
      clearInterval(controlIntervalStatus.current)
    }
  }, [rendered])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <ToastContainer />
      <div className={`page-content ${styles.ptb}`}>
        <MetaTags>
          <title>Detalhes da Instância | Eventozz</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Detalhes da Instância</h6>
              </Col>
            </Row>
          </div>

          <Row>
            <Col xs={12} md={8}>
              <Col xs={12} style={{ paddingBottom: 15 }}>
                <FormGroup>
                  <Label>Nome</Label>
                  <Input
                    type="text"
                    name="instanceName"
                    value={instanceData.instanceName}
                    readOnly
                  />
                </FormGroup>
              </Col>

              <Col xs={12} style={{ paddingBottom: 15 }}>
                <FormGroup>
                  <Label>Telefone conectado</Label>
                  <Input
                    type="text"
                    name="instanceName"
                    value={instanceData.instancePhone}
                    readOnly
                  />
                </FormGroup>
              </Col>

              <Col xs={12} style={{ paddingBottom: 15 }}>
                <FormGroup>
                  <Label>Token</Label>
                  <Input
                    type="text"
                    name="instanceName"
                    value={instanceData.id}
                    readOnly
                  />
                </FormGroup>
              </Col>

              <Col xs={12} style={{ paddingBottom: 15 }}>
                <FormGroup>
                  <Label>Conectada</Label>
                  <Input
                    type="text"
                    name="instanceName"
                    value={instanceData.connected}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Col>
            <Col xs={12} md={4}>
              <div className={styles.textHeaderContainer}>
                <h4 className="page-title">
                  {isAuthenticated ? 'Conectado' : 'Leia o QRCode'}
                </h4>
                <h6 className="page-title">
                  {isAuthenticated
                    ? 'Sua instância está conectada, você já pode enviar mensagens via API'
                    : 'Abra o aplicativo do whatsapp e leia o QRCode abaixo para se conectar a esta instância'}
                </h6>
              </div>
              <div className={styles.containerQrcode}>
                {isLoadingQrcode ? (
                  <div className={styles.loadingQrcodeContainer}>
                    <div className={`spinner-border`} role="status"></div>
                    <div className={styles.loadingText}>Carregando Qr code</div>
                  </div>
                ) : showRefreshButton ? (
                  <div
                    className={styles.containerRefreshQrcode}
                    onClick={handleClickRefresh}
                  >
                    <div
                      style={{ color: '#00ac5a' }}
                      className={styles.containerImgRefreshQrcode}
                    >
                      <img
                        src={RefreshIcon}
                        alt="Ícone de refresh"
                        className={styles.iconRefresh}
                      />
                      <img
                        src={QrCodeBackground}
                        alt="qr code background"
                        className={styles.qrCodeBackground}
                      />
                    </div>
                    Clique para recarregar o qr code
                  </div>
                ) : isAuthenticated ? (
                  <div
                    style={{ color: '#00ac5a' }}
                    className={styles.constainerIsAuthenticated}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      viewBox="0 0 512 512"
                      height="40"
                      width="40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                    </svg>
                  </div>
                ) : (
                  <img src={qrcodeUrl} alt="Imagem qr code" />
                )}
              </div>
              {queueCount ? (
                <>
                  <div className={styles.queueCount}>
                    Você possui {queueCount}{' '}
                    {queueCount > 1 ? 'mensagens' : 'mensagem'} na fila de envio
                    dessa instância
                  </div>
                  <div
                    className={`${styles.queueCount} ${styles.queueDelete}`}
                    onClick={() => setShowModalConfirm(true)}
                  >
                    Remover mensagens da fila
                    <Trash />
                  </div>
                </>
              ) : null}
              <div className={styles.buttonRefresh}>
                <Button
                  variant="danger"
                  onClick={() => confirmRefreshInstance(instanceId)}
                >
                  Atualizar instância <RefreshCw />
                </Button>
              </div>
            </Col>
          </Row>
          <ModalConfirm
            showModal={showModalConfirm}
            text="Remover todas as mensagens da fila dessa instância?"
            title="Apagar mensagem da fila"
            onCancel={handleCancelRemoveQueue}
            onConfirm={handleRemoveQueue}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
